import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { TranslateModule } from '@ngx-translate/core';

import {
  AuthTokenService,
  BasketCreatorService,
  PipesModule,
  ProductsModule
} from '@ui/legacy-lib';

import {
  AuthenticationComponent,
  EditLandlordComponent,
  ObjectHierarchyComponent,
  PaymentMethodComponent,
  PreferencesComponent,
  SubscriptionComponent
} from './components/edit-landlord';

import { effects, reducers } from './+state';
import { guards } from './guards';
import { ROUTES } from './landlord.routes';
import { LandlordComponent } from './landlord.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    RouterModule.forChild(ROUTES),
    ProductsModule,
    PipesModule,
    LandlordComponent,
    AuthenticationComponent,
    PaymentMethodComponent,
    EditLandlordComponent,
    PreferencesComponent,
    SubscriptionComponent,
    ObjectHierarchyComponent
  ],
  providers: [
    AuthTokenService,
    BasketCreatorService,
    ...guards,
    provideState('editLandlord', reducers),
    provideEffects(effects)
  ]
})
export class LandlordModule {}
